import './utils/ArrayFindPolyfill';
import './utils/ArrayFromPolyfill';
import './utils/hidePreviewBarInDev';
import './utils/NodeListForEachPolyfill';
import './utils/customEventPolyfill';
import 'polyfill-object.fromentries';
import smoothscroll from 'smoothscroll-polyfill';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/rias/ls.rias';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';

import './theme.scss';

import { load } from '@shopify/theme-sections';

import { bindInPageLinks, focusHash } from '@shopify/theme-a11y';

import './components/accordion';
import './components/added-to-cart-modal';
import './components/announcement-slider';
import './components/back-to-top-button';
import './components/cart-indicator';
import './components/gift-card';
import './components/wishlist';

smoothscroll.polyfill();



const $tradeRegistrationForm = document.querySelector('form[data-cf-form]');
if ($tradeRegistrationForm) {
    $tradeRegistrationForm.addEventListener('cf:form_submitted', function (event) {
        document.body.classList.add('cf-form-submitted');
    });
}

// Common a11y fixes
focusHash();
bindInPageLinks();

load('*');
